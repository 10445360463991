import "./Home.css";
import img_1 from "../../images/home/1.jpeg";
import img_2 from "../../images/home/2.jpeg";
import img_3 from "../../images/home/3.jpeg";
import img_4 from "../../images/home/4.jpeg";
import img_5 from "../../images/home/5.jpeg";
import img_6 from "../../images/home/6.jpeg";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

// Array of all the images to be displayed in the slideshow
const images = [img_1, img_2, img_3, img_4, img_5, img_6];

export const Home = () => {
  return (
    <div className="slideshow">
      <Slide>
        {images.map(function (image) {
          return (
            <div className="each-slide-effect" key={image} >
              <div style={{ 'backgroundImage': `url(${image})` }}>
              </div>
            </div>)
        })}
      </Slide>
    </div>
  );
};
