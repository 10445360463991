import { useTranslation } from "react-i18next";

export const TreeMap = () => {
  const { i18n } = useTranslation();
  return (
    <iframe
      title="map"
      src={"treeMap/map.html?lang=" + i18n.language}
      style={{ height: "100%" }}
    />
  );
};
