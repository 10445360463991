import { Calendar } from "./components/Calendar/Calendar";
import { Header } from "./components/Header/Header";
import { Home } from "./components/Home/Home";
import { Resources } from "./components/Resources/Resources";
import { TreeMap } from "./components/TreeMap";
import { About } from "./components/About/About";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <Router>
        <Header />
        <Routes>
          <Route path="/map" element={<TreeMap />} />
          <Route path="/events" element={<Calendar />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/about" element={<About />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
