import { useState } from "react";
import "./Calendar.css";
import { useTranslation } from "react-i18next";

export const Calendar = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const doneLoading = () => {
    setLoading(false);
  };

  const calendarBaseURL = "https://calendar.google.com/calendar/embed?height=550&wkst=1&bgcolor=%23ffffff&ctz=America%2FLos_Angeles&showPrint=0&showCalendars=0&src=Y19hZWQ5ZDcyY2QwM2FkMzI2OTMyYTdiM2ZkODI0ODM5MjRkYWFlNzIxNWVhMGFiMGY1ODk4MWU1NWZjNmNkZDdmQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23D81B60";

  const onClickSubmitEvent = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSdxQkLPFXJTHSe3GwmvryMpc7W8zjpkDZQiezjbTp3bzRHsGw/viewform"
    );
  };

  return (
    <div className="calendar_page">
      <div className="accent_bar" />
      <div>
        {t('calendar.subtitle')}
      </div>

      {/* TODO: the title of the calendar is given from google and is not translated */}
      <div className="cal">
        {loading && <div className="loading">{t('calendar.loading')}</div>}
        <iframe
          title={t('calendar.frame.title')+''}
          src={calendarBaseURL + "&hl=" + i18n.language + "&showTitle=0"}
          style={{ border: "solid 1px #777" }}
          width="800"
          height="550"
          frameBorder="0"
          scrolling="no"
          onLoad={doneLoading}
        ></iframe>
      </div>
      <button style={{ marginBottom: "30px" }} onClick={onClickSubmitEvent}>
        {t('calendar.submit_text')}
      </button>
    </div>
  );
};
