import "./Resources.css";
import lemon_tree from "../../images/lemon_tree.jpeg";
import report from "../../images/report.png";
import purple_tree from "../../images/purple_tree.jpg";
import sapling from "../../images/sapling.jpg";
import arbor_day_foundation from "../../images/arbor_day_foundation.png";
import map_help from "../../images/map_help.png";
import mailing_list from "../../images/mailing_list.jpg";
import { useTranslation } from "react-i18next";

export const Resources = () => {
  const { t } = useTranslation();
  return (
    <div className="resources_page">
      <div className="resources">
        <ResourceItem
          headline={t('resources.long_beach_planting_program.title')}
          image={lemon_tree}
          blurb={<div>{t('resources.long_beach_planting_program.description')}</div>}
          link={"https://www.longbeach.gov/sustainability/programs/tree-planting/"}
        />
        <ResourceItem
          headline={t('resources.report_issue.title')}
          image={report}
          blurb={(
            <div>
              {t('resources.report_issue.description.0')}
              <a href={"tel:" + t('resources.report_issue.description.phone_number')}>{t('resources.report_issue.phone_number')}</a>
              {t('resources.report_issue.description.1')}
              <a href="https://www.longbeach.gov/ti/modernization/go-long-beach-apps/">{t('resources.report_issue.app')}</a>
              {t('resources.report_issue.description.2')}
              <a href="https://www.longbeach.gov/pw/services/public-trees/#:~:text=Report%20a%20public%20tree%20issue%20by%20calling%20(562)%20570%2D,the%20Go%20Long%20Beach%20website.">{t('resources.report_issue.website')}</a>
              {t('resources.report_issue.description.3')}
            </div>
          )}
          link={"https://www.longbeach.gov/pw/services/public-trees/#:~:text=Report%20a%20public%20tree%20issue%20by%20calling%20(562)%20570%2D,the%20Go%20Long%20Beach%20website."}
        />
        <ResourceItem
          headline={t('resources.tree_steward.title')}
          image={purple_tree}
          blurb={<div>{t('resources.tree_steward.description')}</div>}
          link={"https://forms.gle/4uwiRSFTBVRsiRKY6"}
        />
        <ResourceItem
          headline={t('resources.tree_care.title')}
          image={sapling}
          blurb={(
            <div>
              <a href="https://drive.google.com/file/d/1PORFcDKtNhM79jsNRgclawNLVWdRDdO3/view">{t('resources.tree_care.description.0')}</a>
              {t('resources.tree_care.description.1')}
              <br></br>
              <a href="https://drive.google.com/file/d/1V6LXAAXFAFSI1HqkBpDwTI0GhDN9L8pL/view">{t('resources.tree_care.description.2')}</a>
              {t('resources.tree_care.description.3')}
            </div>
          )}
        />
        <ResourceItem
          headline={t('resources.arbor_day_foundation.title')}
          image={arbor_day_foundation}
          blurb={<div>{t('resources.arbor_day_foundation.description')}</div>}
          link={"https://www.arborday.org/"}
        />
        <ResourceItem
          headline={t('resources.map_help.title')}
          image={map_help}
          blurb={(
            <div>
              <a href="">{t('resources.map_help.description.0')}</a>
              {t('resources.map_help.description.1')} 
            </div>
          )}
          link={""}
        />
        <ResourceItem
          headline={t('resources.mailing_list.title')}
          image={mailing_list}
          blurb={(
            <div>
              <a href="https://forms.gle/MA7xSmYThUxAcXVW6">{t('resources.mailing_list.description.0')}</a>
              {t('resources.mailing_list.description.1')} 
            </div>
          )}
        />
      </div>
    </div>
  );
};

const ResourceItem = ({
  headline,
  image,
  blurb,
  link,
}: {
  headline: string;
  image: string;
  blurb: string | React.ReactNode;
  link?: string;
}) => {
  const { t } = useTranslation();
  return (
    <div className="resource_item">
      <div className="image">
        <a href={link}>
          <img src={image} alt={""} />
        </a>
      </div>
      <div className="blurb">
        <div className="headline">
          {headline}
        </div>
        {blurb}
        <br></br>
        {link ? <a href={link}>{t('resources.resource_item.read_more')}</a> : ""}
      </div>
    </div>
  );
};
