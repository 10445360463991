import "./About.css";
import { useTranslation } from "react-i18next";
import centreForPublicImpact from "../../images/centre_for_public_impact.svg";
import beeckCenter from "../../images/beeck_center.svg";
import georgetown from "../../images/georgetown_university.png";
import knightFoundation from "../../images/knight_foundation.png";
import googleOrg from "../../images/google_org.png";
import longBeachSustainability from "../../images/long_beach_sustainability.png";
import westsideElevate from "../../images/westside_elevate.png";
import puente from "../../images/puente.png";
import firehouse from "../../images/firehouse.png";

export const About = () => {
  const { t } = useTranslation();
  return (
    <div className="about_page">
      <div className="images">
        <a href="https://www.centreforpublicimpact.org/"><img src={centreForPublicImpact} alt="" /></a>
        <a href="https://beeckcenter.georgetown.edu/"><img src={beeckCenter} alt="" /></a>
        <a href=""><img src={georgetown} alt="" /></a>
        <a href="https://knightfoundation.org/"><img src={knightFoundation} alt="" /></a>
        <a href="https://www.google.org/"><img src={googleOrg} alt="" /></a>
        <a href="https://www.longbeach.gov/sustainability/"><img src={longBeachSustainability} alt="" /></a>
        <br></br>
        <a href="https://westsidelb.com/"><img src={westsideElevate} alt="" /></a>
        <a href="https://www.puentela.org/"><img src={puente} alt="" /></a>
        <a href="https://www.firehousecommunityfarm.org/"><img src={firehouse} alt="" /></a>
      </div>
      <div className="accent_bar" />
      <div className="about_section">
        <div className="about_header">
          <div>{t('about.sections.0.title')}</div>
        </div>
        <p className="about_content">
          <div>
            {t('about.sections.0.content.0')}
            <a href="https://www.longbeach.gov/sustainability/">{t('about.sections.0.content.1')}</a>
            {t('about.sections.0.content.2')}
            <a href="https://www.puentela.org/">{t('about.sections.0.content.3')}</a>
            {t('about.sections.0.content.4')}
            <a href="https://westsidelb.com/">{t('about.sections.0.content.5')}</a>
            {t('about.sections.0.content.6')}
            <a href="https://www.firehousecommunityfarm.org/">{t('about.sections.0.content.7')}</a>
            {t('about.sections.0.content.8')}
            <a href="https://www.google.org/">{t('about.sections.0.content.9')}</a>
            {t('about.sections.0.content.10')}
            <br></br>
            <br></br>
            <a href="">{t('about.sections.0.content.11')}</a>
            {t('about.sections.0.content.12')}
          </div>
        </p>
      </div>
      <div className="about_section">
        <div className="about_header">
          <div>{t('about.sections.1.title')}</div>
        </div>
        <p className="about_content">
          <div>{t('about.sections.1.content')}</div>
        </p>
      </div>
      <div className="about_section">
        <div className="about_header">
          <div>{t('about.testimonials.title')}</div>
        </div>
        <p className="about_content">
          {t('about.testimonials.content.0.text')}<br></br><b>- {t('about.testimonials.content.0.name')}, {t('about.testimonials.content.0.title')}</b>
        </p>
        <p className="about_content">
          {t('about.testimonials.content.1.text')}<br></br><b>- {t('about.testimonials.content.1.name')}, {t('about.testimonials.content.1.title')}</b>
        </p>
        <p className="about_content">
          {t('about.testimonials.content.2.text')}<br></br><b>- {t('about.testimonials.content.2.name')}, {t('about.testimonials.content.2.title')}</b>
        </p>
        <p className="about_content">
          {t('about.testimonials.content.3.text')}<br></br><b>- {t('about.testimonials.content.3.name')}, {t('about.testimonials.content.3.title')}</b>
        </p>
        <p className="about_content">
          {t('about.testimonials.content.4.text')}<br></br><b>- {t('about.testimonials.content.4.name')}, {t('about.testimonials.content.4.title')}</b>
        </p>
      </div>
      <div className="about_section">
        <div className="about_header">
          <div>{t('about.sections.2.title')}</div>
        </div>
        <p className="about_content">
          <ul>
            <li><a href="https://www.centreforpublicimpact.org/insights/the-opportunity-project-for-cities-2022-cohort">{t('about.sections.2.content.0')}</a></li>
            <li><a href="https://twitter.com/Googleorg">{t('about.sections.2.content.1')}</a></li>
            <li><a href="https://youtu.be/C9ZcuqJgJkg">{t('about.sections.2.content.2')}</a></li>
          </ul>
        </p>
      </div>
    </div >
  );
};
