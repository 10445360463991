import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import english from "./translations/english.json";
import vietnamese from "./translations/vietnamese.json"
import spanish from "./translations/spanish.json"
import tagalog from "./translations/tagalog.json"

// Note these need to be ISO 639-1 language codes so the google calendar is automatically translated.
// See: https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
const resources = {
  en: {
    translation: english,
    display: "English",
  },
  vi: {
    translation: vietnamese,
    display: "Tiếng Việt",
  },
  es: {
    translation: spanish,
    display: "Español",
  },
  tl: {
    translation: tagalog,
    display: "Tagalog",
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector) // Automatically chooses based on user's language.
  .init({
    lng: "en",
    resources,
    defaultNS: "translation",
    fallbackLng: "en",
  });

export default i18n;
export const availableLanguages = Object.keys(resources);
export const availableLanguagesDisplay = Object.entries(resources)
