import "./Header.css";
import logo from "../../images/logo.png";
import languageIcon from "../../images/language.svg";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { availableLanguagesDisplay } from "../../i18next";

export const Header = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="header">
        <NavLink end to="/" style={{ backgroundColor: "#1d95bc" }}>
          <img className="logo_image" src={logo} alt={t("header.logo_alt")+''} />
        </NavLink>
        <div className="header_links">
          <NavLink end role="button" to="/">
            {t("header.home")}
          </NavLink>
          <NavLink role="button" to="/map">
            {t("header.map")}
          </NavLink>
          <NavLink role="button" to="/events">
            {t("header.events")}
          </NavLink>
          <NavLink role="button" to="/resources">
            {t("header.resources")}
          </NavLink>
          <NavLink role="button" to="/about">
            {t("header.about")}
          </NavLink>
        </div>
        <div className="right_side">
          <div className="language">
            <img src={languageIcon} alt={""} width="20px" height="20px" />
            <select
              defaultValue={i18n.language}
              onChange={(e) => {
                i18n.changeLanguage(
                  availableLanguagesDisplay[e.target.selectedIndex][0]
                );
              }}
            >
              {availableLanguagesDisplay.map((language) => (
                <option key={language[0]}>{language[1].display}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
};
